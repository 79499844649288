<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat>
        <span v-if="action == 'agregar'">Agregar URE</span>
        <span v-else>Modificar URE</span>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col md="4" sm="12" style="padding-bottom: 0px">
            <v-text-field
              v-model="numero"
              name="SG-numero"
              label="Número"
              type="text"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
              :error-messages="numeroErrors"
              @input="$v.numero.$touch()"
              @blur="$v.numero.$touch()"
            >
            </v-text-field>
          </v-col>
          <v-col md="4" sm="12" style="padding-bottom: 0px">
            <v-text-field
              v-model="tipo"
              name="SG-tipo"
              label="Tipo"
              type="text"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
              :error-messages="tipoErrors"
              @input="$v.tipo.$touch()"
              @blur="$v.tipo.$touch()"
            >
            </v-text-field>
          </v-col>
          <v-col md="4" sm="12" style="padding-bottom: 0px">
            <v-text-field
              v-model="entidad"
              name="SG-entidad"
              label="Entidad"
              type="text"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" style="padding-top: 0px">
            <v-text-field
              v-model="nombre"
              name="SG-nombre"
              label="Nombre"
              type="text"
              :disabled="loading"
              color="blue-grey"
              outlined
              dense
              :error-messages="nombreErrors"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div v-if="error" class="error-text">{{ error }}</div>
      </v-container>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          :disabled="loading"
          outlined
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :class="{ 'disable-events': disableAction }"
          small
          dark
          :loading="loading"
          @click="actionEvent()"
        >
          {{ action }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { agregarUreService, modificarUreService } from "./ures.service";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "ureComponent",
  props: ["mostrar", "action", "ureSuperior", "ureAModificar"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userName"]),
    numeroErrors() {
      const errors = [];
      if (!this.$v.numero.$dirty) return errors;
      !this.$v.numero.required && errors.push("Campo requerido.");
      !this.$v.numero.minLength && errors.push("Mínimo 5 caracteres.");
      return errors;
    },
    tipoErrors() {
      const errors = [];
      if (!this.$v.tipo.$dirty) return errors;
      !this.$v.tipo.required && errors.push("Campo requerido.");
      return errors;
    },
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      !this.$v.nombre.minLength && errors.push("Mínimo 3 caracteres.");
      return errors;
    },
    // entidadErrors() {
    //   const errors = [];
    //   if (!this.$v.entidad.$dirty) return errors;
    //   !this.$v.entidad.required && errors.push("Campo requerido.");
    //   !this.$v.entidad.minLength && errors.push("Mínimo 3 caracteres.");
    //   return errors;
    // },
    disableAction() {
      return (
        this.numeroErrors.length > 0 ||
        this.nombreErrors.length > 0 ||
        !this.numero ||
        !this.tipo ||
        !this.nombre
        // !this.entidad
      );
    },
  },
  validations: {
    numero: { required, minLength: minLength(5) },
    tipo: { required },
    nombre: { required, minLength: minLength(3) },
    // entidad: { required, minLength: minLength(3) },
  },
  data: () => ({
    loading: false,
    idUre: null,
    numero: null,
    tipo: null,
    nombre: null,
    entidad: null,
    error: null,
  }),
  created() {
    if (this.action == "modificar") {
      this.idUre = this.ureAModificar._id;
      this.numero = this.ureAModificar.numero;
      this.tipo = this.ureAModificar.tipo;
      this.nombre = this.ureAModificar.nombre;
      this.entidad = this.ureAModificar.entidad;
    }
  },
  methods: {
    async actionEvent() {
      try {
        this.error = null;
        this.loading = true;
        const sendData = {
          _id: this.idUre,
          numero: this.numero,
          tipo: this.tipo,
          nombre: this.nombre,
          entidad: this.entidad,
        };

        let serverResponse = null;
        if (this.action == "agregar")
          serverResponse = await agregarUreService(this.ureSuperior, sendData);
        else serverResponse = await modificarUreService(sendData);

        this.loading = false;
        if (!serverResponse.ok) this.error = serverResponse.mensaje;
        else {
          const emitData = { ure: serverResponse.ure };
          if (this.action == "agregar") this.$emit("ureAgregada", emitData);
          else this.$emit("ureModificada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>

<style >
.error-text {
  color: brown;
}
</style>