<template>
  <v-card :loading="loading">
    <v-card-title>
      <span v-if="ureActual.numero == 0">URES</span>
      <span v-else
        ><v-btn icon color="secondary"
        @click="$router.go(-1)"
          ><v-icon>mdi-arrow-left-bold</v-icon></v-btn
        >
        {{ ureActual.numero }} {{ ureActual.nombre }}</span
      >
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="secondary"
            x-small
            fab
            dark
            v-bind="attrs"
            v-on="on"
            @click="agregarUre()"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </template>
        <span>Agregar URE</span>
      </v-tooltip>
    </v-card-title>
    <v-divider></v-divider>
    <v-container>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="50"></th>
              <th class="text-left">Número</th>
              <th class="text-left">Nombre</th>
              <th class="text-left">Entidad</th>
              <th class="text-left">Tipo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ure, index) in ures" :key="'ure-' + index">
              <td>
                <v-btn x-small icon color="primary" @click="modificarUre(ure)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </td>
              <td>
                <a
                  class="link"
                  href="javascript:void(0)"
                  @click="$router.push(`/main/ures/${ure.numero}`)"
                  >{{ ure.numero }}</a
                >
              </td>
              <td>{{ ure.nombre }}</td>
              <td>{{ ure.entidad }}</td>
              <td>{{ ure.tipo }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <ureComponent
      v-if="mostrarUreComponent"
      :mostrar="mostrarUreComponent"
      :action="ureAction"
      :ureSuperior="ureActual._id"
      :ureAModificar="ureAModificar"
      @cancelar="mostrarUreComponent = false"
      @ureAgregada="ureAgregada"
      @ureModificada="ureModificada"
    />
  </v-card>
</template>

<script>
import ureComponent from "./ureManagment.vue";
import { getUres } from "./ures.service";

export default {
  name: "uresView",
  components: { ureComponent },
  data: () => ({
    loading: false,
    ureAction: null,
    mostrarUreComponent: false,
    ureAModificar: null,
    ureActual: {
      _id: null,
      numero: 0,
      nombre: null,
    },
    ures: [],
  }),
  watch: {
    $route() {
      this.ureActual.numero = this.$route.params.numero;
      this.cargarUre();
    },
  },
  created() {},
  async mounted() {
    this.ureActual.numero = this.$route.params.numero;
    this.cargarUre();
  },
  methods: {
    async cargarUre() {
      try {
        this.loading = true;
        const serverResponse = await getUres(this.ureActual.numero);
        this.loading = false;
        if (!serverResponse.ok) this.$notificarError(serverResponse.mensaje);
        else {
          this.ures = serverResponse.ures;
          if (serverResponse.ureSuperior)
            this.ureActual = serverResponse.ureSuperior;
          else {
            this.ureActual._id = null;
            this.ureActual.numero = 0;
            this.ureActual.nombre = null;
          }
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    agregarUre() {
      this.ureAction = "agregar";
      this.mostrarUreComponent = true;
    },
    modificarUre(ure) {
      this.ureAction = "modificar";
      this.ureAModificar = ure;
      this.mostrarUreComponent = true;
    },
    ureAgregada(data) {
      this.ures.push(data.ure);
      this.mostrarUreComponent = false;
    },
    ureModificada(data) {
      const index = this.ures.findIndex((x) => x._id == data.ure._id);
      if (index >= 0) {
        this.ures[index].numero = data.ure.numero;
        this.ures[index].tipo = data.ure.tipo;
        this.ures[index].nombre = data.ure.nombre;
        this.ures[index].entidad = data.ure.entidad;
      }
      this.mostrarUreComponent = false;
    },
  },
};
</script>

<style>
.link {
  text-decoration: none;
  color: #0000ee !important;
}
</style>