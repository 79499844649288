import { getServerRequest, putServerRequest, patchServerRequest } from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function getUres(ureSuperior) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/ures/${ureSuperior}`;
    return await getServerRequest(url, config);
}

export async function agregarUreService(ureSuperior, ureData) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/ures/${ureSuperior}`;
    return await putServerRequest(url, ureData, config);
}

export async function modificarUreService(ureData) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/ures/${ureData._id}`;
    return await patchServerRequest(url, ureData, config);
}